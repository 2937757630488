<template>
  <div>
    <ion-item class="item-container d-flex pointer no-select">
      <div class="menu-item d-flex align-items-center no-select">
        <router-link
          v-if="!isMobSmallScreen"
          :to="{ name: 'character-profile-new', params: { slug: character.slug } }"
          target="_blank"
          title="See Character Details"
          class="mr-1"
          @click.stop
        >
          <i class="ti-info-alt" style="font-size: 14px" title="See Character Details" /> <span>Character Profile</span>
        </router-link>
      </div>
    </ion-item>
    <report
      :is-popover="true"
      :report-entity-id="character?.id"
      :report-entity-type="'image'"
      :reported-user="get(character, 'author')"
      :reported-from="'character-roulette'"
      @close="dismiss"
    >
      <ion-item class="item-container d-flex pointer no-select">
        <div class="menu-item d-flex align-items-center no-select text-danger">
          <i class="ti-alert mr-1" /><span>Report Character </span>
        </div>
      </ion-item>
    </report>
  </div>
</template>

<script lang="ts" setup>

import Report from '@/shared/components/Report.vue';
import { OverlayInterface } from '@ionic/core';
const { isMobSmallScreen } = useWindowSize();
const props = defineProps({
  instance: {
    type: Object as PropType<OverlayInterface>,
  },
  character: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
  },
});

const instance = toRef(props, 'instance') as any;
const character = toRef(props, 'character') as any;

const dismiss = async () => {
  await instance.value.dismiss();
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
