<template>
  <Transition>
    <div v-if="showRoulette" class="characters w-100 my-3">
      <div class="title text-center mb-2">{{ get(reactionPhrases, `${selectedReaction}.text`, '') }}</div>
      <div class="text-center text-gray mb-2">Vote to like – spread the love!</div>
      <div class="d-flex">
        <template v-for="(character, index) in characters" :key="index">
          <ion-card
            class="character-card px-1 mx-1 py-3 clickable-item-hov mx-0"
            :class="`bg-char${index ? '-alt' : ''}`"
            @click.prevent="reactToCharacter(index)"
          >
            <div class="one-character" :class="{ 'd-flex': !isMobSmallScreen }">
              <div :class="{ 'd-flex justify-content-center': isMobSmallScreen }">
                <img
                  v-image
                  class="char-img"
                  :src="resizeUpload(get(character, 'info.cropProfilePicture', '/empty.png'), '80x80')"
                />
              </div>
              <div
                class="name d-flex"
                :class="isMobSmallScreen ? 'justify-content-center mt-2 text-center' : 'align-self-center ml-2'"
              >
                {{ get(character, 'info.name', 'Unknown') }}
              </div>
              <div class="d-flex mr-2" @click.stop="dropDownToggle($event, character, index)">
                <i class="ti-more-alt header-popover-button d-block" />
              </div>
            </div>
            <p>
              {{ truncateText(stripHtmlTags(get(character, 'info.description', ''))) }}
            </p>

            <div v-for="key in keys(get(character, 'info.about', {})).splice(0, 3)" :key="key">
              <strong>{{ toTitleCase(key) }}: </strong>{{ truncateText(character.info.about[key], 50) }}
            </div>
          </ion-card>
        </template>
      </div>
      <div class="text-center w-100 mt-3 d-flex flex-column align-items-center justify-content-between">
        <ion-button class="clickable-item-hov" color="white" @click="fetchGameCharacters">Skip</ion-button>
        <ion-button color="medium" @click="close">Close</ion-button>
      </div>
    </div>
    <div v-else-if="showFinishMsg" class="characters w-100 py-4">
      <div class="finish-msg text-center">
        <p class="big">😮</p>
        <p>Wow! You've gone through every character in our system!</p>
        <p>Check back later to see if any more appear.</p>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { resizeUpload } from '@/shared/utils/upload';
import { Action } from 's-vuex-class';
import { toTitleCase, stripHtmlTags } from '@/shared/utils/string';
import constants from '@/shared/statics/constants';
import { getRouletteCharacters } from '@/shared/actions/characters';
import namespace from '@/shared/store/namespace';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { reactionsNames } from '@/shared/statics/reactions';
import { Character } from '@/shared/types/static-types';
import CharRoulettePopover from '../popovers/CharRoulettePopover.vue';
import { popovers } from '@/shared/native/popovers';
import { truncateText } from '@/shared/utils/string';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'CharacterRoulette',
})
export default class CharacterRoulette extends Vue {
  @Prop({ default: false }) withClose!: boolean;

  public close() {
    this.$emit('close');
  }

  toTitleCase = toTitleCase;
  get = get;
  keys = keys;
  resizeUpload = resizeUpload;

  stripHtmlTags = stripHtmlTags;
  truncateText = truncateText;

  public showRoulette = false;
  public showFinishMsg = false;
  public selectedReaction = 0;

  public reactionPhrases = constants.charRouletteReactions;
  public characters = [] as Character[];

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  @Action('react', { namespace: namespace.CharactersModule })
  public react!: any;

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public async fetchGameCharacters() {
    this.showRoulette = false;
    this.selectedReaction = 0; // Math.floor(Math.random() * 8);
    this.characters = await getRouletteCharacters();

    this.showRoulette = get(this.characters, 'length', 0) >= 2;
    if (!this.showRoulette) {
      setTimeout(() => (this.showFinishMsg = true), 250);
    }
  }
  public dropDownToggle(ev: CustomEvent, character: any, index: any) {
    popovers.open(ev, CharRoulettePopover, { character, index });
  }
  public async reactToCharacter(charIndex: number) {
    this.showRoulette = false;
    await this.react({
      reaction: this.reactionPhrases[this.selectedReaction].value,
      character: this.characters[charIndex],
      fromRoulette: true,
    });
    this.$emit('select');
    sendAnalyticsEvent('played roulette', {
      chosenCharacter: get(this.characters[charIndex], 'id'),
      characterLost: get(this.characters[charIndex ? 0 : 1], 'id'),
      reaction: get(reactionsNames, get(this.reactionPhrases, `${this.selectedReaction}.value`, ''), ''),
      reactionPhrase: get(this.reactionPhrases, `${this.selectedReaction}.text`, ''),
    });
    await this.fetchGameCharacters();
  }

  mounted() {
    this.fetchGameCharacters();
  }
}
</script>
<style lang="sass" scoped>
.header-popover-button
  position: absolute
  right: 5px
  top: 23px
  width: 32px
  //color: white
  height: 16px
  font-size: 15px
ion-button
  width: 100px
.title
  font-size: 24px
  font-weight: bold
.finish-msg
  font-size: 18px
  font-weight: bold
.characters
  overflow-x: auto
  .one-character
    img
      min-width: 60px
      width: 60px !important
      height: 60px !important
      border-radius: 40px
      border: solid gray 0.1px
      object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
      word-break: break-word
      width: 100%
.character-card
  width: 50%

.bg-char
  background: #73E1B2
  color: black
.bg-char-alt
  background: var(--ion-color-primary)
  color: white
.details-eye-char
  background-color: rgba(0, 0, 0, 0.1)
  font-size: 1.25rem
  color: black
  height: 2rem
  width: 3rem
  line-height: 2.25rem
  padding-left: 0.4rem
.details-eye-char-alt
  background-color: rgba(0, 0, 0, 0.1)
  font-size: 1.25rem
  color: white
  height: 2rem
  width: 3rem
  line-height: 2.25rem
  padding-left: 0.4rem
.v-enter-active,
.v-leave-active
  transition: opacity 250ms ease
.v-enter-from,
.v-leave-to
  opacity: 0
.big
  font-size: 3rem
.more-info
  border-radius: 8px
  &:hover
    // tint background color
    background-color: rgba(255, 255, 255, 0.3)
</style>
