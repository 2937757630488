<template>
  <Transition>
    <div class="w-100">
      <div class="arts w-100 my-3">
        <div class="title text-center mb-2">Which art do you like more? 😍</div>
        <div class="text-center text-gray mb-2">Vote to like – spread the love!</div>
        <div v-if="showRoulette" class="d-flex-column">
          <div class="w-100 imgs-container mx-0 d-flex">
            <ion-card class="clickable-item-hov mr-1 mt-1 mb-1" @click.prevent="reactToImage(0)">
              <div style="position: relative" @click.stop="dropDownToggle($event, images[0])">
                <i class="ti-more-alt header-popover-button d-block" />
              </div>
              <div class="one-card">
                <img loading="lazy" v-image :src="resizeUpload(get(images[0], 'image', '/empty.png'), '240h')" />
              </div>
            </ion-card>
            <ion-card class="clickable-item-hov ml-1 mt-1 mb-1" @click.prevent="reactToImage(1)">
              <div style="position: relative" @click.stop="dropDownToggle($event, images[1])">
                <i class="ti-more-alt header-popover-button d-block" />
              </div>
              <div class="one-card">
                <img loading="lazy" v-image :src="resizeUpload(get(images[1], 'image', '/empty.png'), '240h')" />
              </div>
            </ion-card>
          </div>
          <div class="w-100 mx-0 d-flex">
            <p class="ml-2 w-50">
              <span v-if="images?.[0].description">{{ truncateEasy(images[0].description, 50) }}<br /></span>
              <span v-if="images?.[0].source">Source: {{ truncateEasy(images[0].source, 50) }}<br /></span>
              <span v-if="images?.[0].public_location"
                ><router-link target="_blank" class="link clickable-item-hov" :to="getPath(images[0])"
                  >See details</router-link
                ></span
              >
            </p>
            <p class="ml-2 w-50">
              <span v-if="images?.[1].description">{{ truncateEasy(images[1].description, 50) }}<br /></span>
              <span v-if="images?.[1].source">Source: {{ truncateEasy(images[1].source, 50) }}<br /></span>
              <span v-if="images?.[1].public_location"
                ><router-link target="_blank" class="link clickable-item-hov" :to="getPath(images[1])"
                  >See details</router-link
                ></span
              >
            </p>
          </div>
        </div>
        <div class="text-center w-100 skip">
          <ion-button
            :disabled="loading"
            class="clickable-item-hov no-select p-n1"
            color="white"
            @click="fetchGameImages"
            >Skip</ion-button
          >
        </div>
      </div>
      <div v-if="showFinishMsg" class="arts w-100 py-4">
        <div class="finish-msg text-center">
          <p class="big">😮</p>
          <p>Wow! You've gone through every image we have for you!</p>
          <p>Check back later to see if any more appear.</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action } from 's-vuex-class';
import { IonCard } from '@ionic/vue';
import { truncateEasy, toTitleCase, stripHtmlTags } from '@/shared/utils/string';
import constants from '@/shared/statics/constants';
import { getRouletteImages } from '@/shared/actions/imagesCollections';
import namespace from '@/shared/store/namespace';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { Image } from '@/shared/types/static-types';
import ArtRoulettePopover from '../popovers/ArtRoulettePopover.vue';
import { popovers } from '@/shared/native/popovers';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'ArtRoulette',
  components: { IonCard },
})
export default class ArtRoulette extends Vue {
  toTitleCase = toTitleCase;
  get = get;
  keys = keys;
  stripHtmlTags = stripHtmlTags;
  truncateEasy = truncateEasy;
  resizeUpload = resizeUpload;

  public showRoulette = false;
  public showFinishMsg = false;
  public selectedReaction = 0;

  public reactionPhrases = constants.rouletteReactions;
  public images = [] as Image[];
  public allImages = [] as Image[];
  public offset = 0;
  public limit = 2;

  loading = false;

  public windowWidth = window.innerWidth;

  @Action('reactImage', { namespace: namespace.ImagesModule })
  public reactImage!: any;

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public getPath(image: Image) {
    if (!image.public_location) return '';
    if (image.public_location.model === 'character') {
      return {
        name: 'character-profile-new',
        params: { slug: image.public_location.slug },
        query: { img: image.id },
      };
    }
    if (image.public_location.model === 'world') {
      return {
        name: 'world-details',
        params: { slug: image.public_location.slug },
        query: { img: image.id },
      };
    }
    return '';
  }

  public async fetchGameImages() {
    this.showRoulette = false;
    this.selectedReaction = 0;

    let skip = false;
    this.limit > this.allImages.length ? (skip = false) : (skip = true);

    if (skip) {
      this.images = (this.allImages || []).slice(this.offset, this.limit);
      this.offset = this.offset += 2;
      this.limit = this.limit += 2;
    } else {
      this.loading = true;
      this.allImages = await getRouletteImages();

      this.offset = 0;
      this.limit = 2;
      this.images = (this.allImages || []).slice(this.offset, this.limit);

      this.offset = this.offset += 2;
      this.limit = this.limit += 2;

      this.loading = false;
    }

    this.showRoulette = get(this.images, 'length', 0) >= 2;
    if (!this.showRoulette) {
      setTimeout(() => (this.showFinishMsg = true), 250);
    }
  }

  public async reactToImage(charIndex: number) {
    this.showRoulette = false;
    await this.reactImage({
      reaction: this.reactionPhrases[this.selectedReaction].value,
      image: this.images[charIndex],
      fromRoulette: true,
    });
    this.$emit('select');
    sendAnalyticsEvent('played art roulette', {
      chosenImage: get(this.images[charIndex], 'id'),
      imageLost: get(this.images[charIndex ? 0 : 1], 'id'),
    });
    await this.fetchGameImages();
  }

  public dropDownToggle(ev: CustomEvent, imageObj: any) {
    popovers.open(ev, ArtRoulettePopover, { image: imageObj });
  }

  public handleResize() {
    this.windowWidth = window.innerWidth;
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchGameImages();
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>
<style lang="sass" scoped>
p
  color: white
ion-button
  width: 100px
ion-card
  --background-color: rgba(0,0,0,0.2) !important
  flex: 1
  justify-content: center
  align-items: center
  width: 100%
  margin-left: 0
  margin-right: 0
.dark ion-card
  --background-color: rgba(0,0,0,0.2) !important
  background-color: rgba(0,0,0,0.2) !important
.title
  font-size: 24px
  font-weight: bold
.finish-msg
  font-size: 18px
  font-weight: bold
.arts
  display: block
  overflow: hidden
  .one-card
    height: 100%
    max-height: 240px
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    @media screen and (max-width: 767px)
      max-height: 40vh
    img
      min-width: 60px
      height: 100%
      border-radius: 6px
      object-fit: contain
      align-self: center
      user-select: none !important
    .name
      font-size: 18px
      font-weight: bold
      word-break: break-word
      width: 100%
.imgs-container
  width: 50%
  height: 75%
  min-height: 200px

.details-eye-char
  background-color: rgba(0, 0, 0, 0.1)
  font-size: 1.25rem
  color: black
  height: 2rem
  width: 3rem
  line-height: 2.25rem
  padding-left: 0.4rem
.details-eye-char-alt
  background-color: rgba(0, 0, 0, 0.1)
  font-size: 1.25rem
  color: white
  height: 2rem
  width: 3rem
  line-height: 2.25rem
  padding-left: 0.4rem
.v-enter-active,
.v-leave-active
  transition: opacity 250ms ease
.v-enter-from,
.v-leave-to
  opacity: 0
.big
  font-size: 3rem
img
  width: 100%
  height: 100%
  object-fit: cover
.skip
  position: absolute
  bottom: 0
  margin-bottom: 0.75rem
.link
  color: white !important
.header-popover-button
  position: absolute
  right: 0px
  //top: 7px
  width: 32px
  color: white
  height: 16px
  font-size: 15px
</style>
