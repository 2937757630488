<template>
  <ion-modal
    class="roulette-modal"
    :is-open="isOpen"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <ion-content style="--ion-background-color: #78004c">
      <ion-button color="transparent" class="top-right" @click.prevent="close">
        <i class="ti-close" />
      </ion-button>
      <section class="cr-modal-container">
        <p v-if="counter && counter <= 12" class="bold">{{ counter }} of 12</p>
        <div v-if="counter <= 12" class="roulette-container">
          <div class="d-block d-sm-none bumper"></div>
          <character-roulette @select="onSelect" withClose @close="close" />
        </div>
        <div v-else class="finish-msg text-center h-100">
          <div class="bumper2"></div>
          <p class="big">🎉</p>
          <p>Hooray! You're Done!</p>
          <p>Come back later to play with more characters.</p>
          <h3 v-if="endsAt" class="counter mt-3">
            <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="endsAt" @end="onEnd">
              <span class="white"
                ><span v-if="hours">{{ hours }}:</span
                ><span v-if="!days"><span v-show="minutes < 10">0</span>{{ minutes }}:</span
                ><span v-if="!days"><span v-show="seconds < 10">0</span>{{ seconds }}</span></span
              >
            </vue-countdown>
          </h3>
        </div>
      </section>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts">
import CharacterRoulette from '@/shared/pages/home/components/CharacterRoulette.vue';
import { getCharacterRouletteProgress } from '@/shared/actions/events';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';

interface Data {
  counter: number;
  endsAt: number;
}

export default defineComponent({
  name: 'CharacterRouletteModal',
  components: { CharacterRoulette },
  props: {
    isOpen: { type: Boolean, required: true },
    close: { type: Function, required: true },
  },
  data() {
    return {
      counter: 1,
      endsAt: 0,
    } as Data;
  },
  watch: {
    async isOpen(newVal: boolean) {
      if (newVal) await this.fetchProgress();
    },
  },
  mounted() {
    this.fetchProgress();
  },
  methods: {
    onSelect() {
      this.counter++;
    },
    onEnd() {
      this.close!();
    },
    async fetchProgress() {
      const { count, ends_at } = await getCharacterRouletteProgress();
      this.endsAt = formatFromNowInMilliseconds(ends_at);
      this.counter = count + 1;
    },
  },
});
</script>

<style scoped lang="sass">
ion-content
  --padding-top: var(--safe-ios-margin)
  --padding-bottom: calc(var(--safe-ios-margin)/3)
.cr-modal-container
  width: 100%
  margin: 1rem 0 1rem 0
  text-align: center
  color: white
  height: 90% !important
  @media(max-width: 767px)
    height: 85% !important
.close-bottom
  width: 200px
  bottom: calc(var(--safe-ios-margin)/3)
  margin-left: auto
  margin-right: auto
.roulette-container
  left: 0
  right: 0
  height: 100%
  margin: 0
  text-align: left
.bumper
  height: 10% !important
.bumper2
  height: 30% !important
.big
  font-size: 3rem
.top-right
  position: absolute
  top: 0.5rem
  top: var(--safe-ios-margin)
  right: 0.5rem
</style>
